.rangeslider-container{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    
}
.slider{
    margin-right: 50px;
    width: 60%;
}
.slider-value{
    text-align: center;
    border: 1px solid #183c2a;
    border-radius: 5px;
    width: 100px;
}

