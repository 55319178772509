
.home-section-title{
    color: #183c2a;
    display: flex;
    align-items: center;
    margin: 10px;
    font-size:18px;
    font-weight: 300;
}

.icon {
    margin-right: 10px;
    margin-left: 10px;
    font-size: 30px;
}

.home-section-form{
    color: #183c2a;
    margin: 20px;
}


.home-right-alternative{
    width: 100%;
    display: flex;
}

/* .show-on-desktop {
    display: block;
    margin: 0px 20px 0px 20px
} */

.hidden{
    display: none;
}

.hide-on-phone{
    display: none;
}

@media only screen and (min-width: 768px){
    .home-container{
        display: flex;
        width: 100%;
        height: 100%;
    }
    .home-left{
        width: 45%;
        height: 100%;
    }
    .home-right{
        width: 55%;
        height: 100%;
        /* background-color: #faf1e5; */
        /* border-radius: 30px 0px 0px 30px; */
        
    }.home-right-alternative{
        width: 55%;
        height: 100%;
        display: flex;
        
    }
    
    .hr-animation{
        width: 50%;
        height: 50%;
        background-color: #183c2a;
    }
    

    .home-section-title{
        margin: 10px;
        font-size: 20px;
        font-weight: 300;
    }

    .icon{
        margin-left: 10px;
        margin-right: 10px;
        font-size: 20px;
    }

    .hidden{
        display: block;
        margin: 0px 20px 0px 20px

    }

    .hide-on-phone{
        width: 55%;
        height: 100%;
        display: flex;
    }

    
}

