.rh-container{
    width: 100%;
    height: 100%;
    margin: 0px 20px 0px 20px;
}

.rh-recipename{
    margin: 20px 0px 20px 5px;
    font-size: 25px;
    color: #183c2a;
    font-weight: 300;

}
.rh-top{
    /* margin: 20px; */
    width: 90%;
    height: 200px;
    display: flex;
    align-self: center;
    justify-content: space-between;


}
.rh-left{
    width: 45%;
    height: 100%;
    background-color: #faf1e5;
    border-radius: 20px;
    overflow-y: auto;
    
    /* background-color: rgb(25, 190, 108,0.1); */
    
}

.rh-left::-webkit-scrollbar{
    width: 0;
    height: 0;
}


.rh-right{
    width: 45%;
    background-color: #faf1e5;
    /* background-color: rgba(40, 205, 122, 0.1); */
    border-radius: 20px;
  
    
}
.rh-title{
    color: #183c2a;
    font-weight: 300;
    margin: 20px;
    font-weight: 500;
    /* position: sticky; */
}

.rh-bottom{
    margin-top: 30px;
    width: 90%;
    height: 40%;
    /* margin: 20px; */
    background-color: #faf1e5;
    /* background-color: rgba(40, 205, 122, 0.1); */
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-self: center;
}
.rh-ingredients{
    margin-left: 20px;
    font-size: 13px;
    color: #183c2a;
    font-weight: 300;
}
.rh-ingredient{
    width: 100%;
    display: flex;
    align-items: center;
    margin: 0px 20px 5px 0px;
    justify-content: space-between;
}
.ing-quant{
    margin-right: 10px;
}

.rh-macros{
    margin:30px 20px 20px 20px;
    font-size: 13px;
    color: #183c2a;
    font-weight: 300;

}

.rh-item{
    margin: 0px 0px 20px 0px ;
    display: flex;
    align-items: center;
}
.rh-icon{
    margin-right: 20px;
}
.rh-insts{
    overflow-y: auto;
    margin: 0px 20px 20px 20px;
    font-weight: 300;
    color: #183c2a;
}

.rh-insts::-webkit-scrollbar{
    width: 0;
    height: 0;
}

.rh-inst{
    margin-bottom: 10px;
}

@media only screen and (min-width: 768px){
    .rh-container{
        width: 100%;
        height: 100%;
    }

    .rh-top{
        height: 30%;
    }

    .rh-ingredients{
        font-size: 15px;
    }
    .rh-macros{
        font-size: 15px;
    }
}