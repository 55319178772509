.App{
  overflow-y: hidden;
  overflow-x: hidden;
}
.app-navbar{
  width: 100%;
  height: 10%; 

}
.app-pages{
  width: 100%;
  height: 100%;
  margin-bottom: 100px;
}

@media only screen and (min-width: 768px){
  .App{
    width: 100vw;
    height: 100vh;
    overflow-y: hidden;
    overflow-x: hidden;
  }

  .app-pages{
    width: 100%;
    height: 90%;
    margin-bottom: 0px;

  }
  
}