.navbar-container{
    height: 100%;
    background-color: #183c2a;
    color: #faf1e5;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.nb-margin{
    margin: 20px;
}

.nb-left{
    font-size: 30px;
    font-weight: 1000;
}