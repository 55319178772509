.form-row{
    margin-top: 20px;
}
.form-preferences{
    display: flex;
}
.form-split-left{
    width: 50%;
    margin-right: 20px;
}
.form-split-right{
    width: 50%;
    margin-left: 20px;
}
.form-control{
    display: flex;
    flex-direction: column;
}
.form-cal{
    width: 100%;
}
.form-macros{
    margin-top: 20px;
}

.form-label{
    margin-bottom: 5px;
}
.control-btn{
    /* margin-top: 100px; */
    display: flex;
    width: 70%;
    align-items: center;
    justify-content: space-between
}
.generate-button{
    width: 120px;
    height: 40px;
    border-radius: 30px;
    background-color: #183c2a;
    color: #faf1e5;
    border: 1px solid #faf1e5;
    cursor: pointer;
    font-size: 15px;
}
.reset-button{
    width: 120px;
    height: 40px;
    border-radius: 30px;
    /* background-color: #183c2a; */
    color: #183c2a;
    border: 1px solid #183c2a;
    cursor: pointer;
    font-size: 15px;
    font-weight: 500;
    background-color: white;
}


@media only screen and (min-width: 768px){
    .control-btn{
       width: 50%;
    }
}